
//import image3 from "../../../Components/Header/Gallery/3.jpg";
import image4 from "../../../Components/Header/Gallery/4.jpg";
import image5 from "../../../Components/Header/Gallery/5.jpg";
import image6 from "../../../Components/Header/Gallery/6.jpg";
import image7 from "../../../Components/Header/Gallery/7.jpg";
import image8 from "../../../Components/Header/Gallery/8.jpg";
import image9 from "../../../Components/Header/Gallery/team 3.jpg";





export const skillsData = [
 
  
    // { name: '', image: image3 },
    { name: '', image: image4 },
    { name: '', image: image5 },
    { name: '', image: image6 },
    { name: '', image: image7 },
    { name: '', image: image8 },
    {name:"Certificates issued to the Team", image:image9}
    

];
